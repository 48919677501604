import React, { useEffect, useState, useRef } from "react";
import Logo from "../assets/images/S_RTAS_Logo.svg";
import { S_RTAS_Subheading } from "../components/Common/Data";
import Navbar from "../components/Navbar/Navbar";
import ButtonList from "../components/Common/ButtonList";
import CommunityScore from "../components/Common/CommunityScore";
import { SWT_MCQCard_Div, SWT_QCard_Div } from "./Style";
import { AiScorePopupSpeakingData } from "../components/Speaking/data";
import SpeakingMicCard from "../components/Speaking/SpeakingMicCard";
import AudioPlayer from "../components/Speaking/AudioPlayer";
import { FlexDiv } from "../assets/styles/style";
import SidePannel from "../components/Common/SidePannel";
import { useAuth } from "../authentication/Auth";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import ShowScriptBox from "../components/Common/ShowScriptBox";
Modal.setAppElement("#root");
import { SidePannelBackdrop } from "../components/Common/Style";
import axios from "axios";
import AiScorePopupSpeakingRS from "../components/Speaking/AiScorePopupSpeakingRS";
import QuestionCard from "../components/Speaking/QuestionCard";
import LoadingModal from "../components/Common/LoadingModal";
import TestHeadingReTellLec from "../components/Common/TestHeadingReTellLec";
import { Base_URL, Base_URL_AiScore } from "../Client/apiURL";

const questionname = "Respond to a situation";

const modalStyle = {
  overlay: {
    zIndex: 1002,
    backdropFilter: "blur(5px)",
    WebkitBackdropFilter: "blur(5px)",
    background: "none",
  },
  content: {
    border: "none",
    background: "transparent",
    inset: "0px",
    padding: "20px 1%",
  },
};

const getAspects = (questionName) => {
  switch (questionName) {
    case "Party":
      return {
        MajA: ["party", "classmates", "restrictions"],
        MinA: ["dietary", "salad", "message"],
      };
    case "Missing Books":
      return {
        MajA: ["community", "missing", "books", "respectfully", "reminding"],
        MinA: ["return", "shared", "library", "timeliness"],
      };
    case "Loud Music":
      return {
        MajA: ["neighbor", "loud", "music", "sleep", "disturbance"],
        MinA: ["diplomacy", "noise", "reduction", "relationship"],
      };
    default:
      return { MajA: [], MinA: [] };
  }
};

const SpeakingRS = () => {
  const [testQuestions, setTestQuestions] = useState([]);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [questionsData, setQuestionsData] = useState(null);
  const [showAnswer, setShowAnswer] = useState(false);
  const [questionId, setQuestionID] = useState();
  const [scorecardOpen, setScorecardOpen] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState([""]);
  const [resetTrigger, setResetTrigger] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState("");
  const [enableSkillsData, setEnableSkillsData] = useState([]);
  const [scoreDataSubmitted, setScoreDataSubmitted] = useState(false);
  const [testQuestionTableId, setTestQuestionTableId] = useState();
  const [myAttemptedQuestionsScore, setMyAttemptedQuestionsScore] =
    useState(null);
  const [communityAttemptedQuestionsData, setCommunityAttemptedQuestionsData] =
    useState(null);
  const [marksObtained, setMarksObtained] = useState(0);
  const [correctAnswers, setCorrectAnswers] = useState([""]);
  const [shouldOpenScorecard, setShouldOpenScorecard] = useState(false);
  const [isScoreDataReadyForSubmit, setIsScoreDataReadyForSubmit] =
    useState(false);
  const [lastScoreUpdate, setLastScoreUpdate] = useState(Date.now());
  const [searchTerm, setSearchTerm] = useState("");
  const [recordedWavFile, setRecordedWavFile] = useState(null);
  const [repeatSentenceScore, setRepeatSentenceScore] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [totalTestTime, setTotalTestTime] = useState(15000);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [filterBookmarked, setFilterBookmarked] = useState(false);
  const [filterPrediction, setFilterPrediction] = useState(false);
  const [wantToSortDesc, setWantToSortDesc] = useState(false);
  const [highFrequency, setHighFrequency] = useState(false);
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [autoStartRecording, setAutoStartRecording] = useState(false);
  const [isRecordingStarted, setIsRecordingStarted] = useState(false);
  const [isRecordingStopped, setIsRecordingStopped] = useState(false);
  const [isAudioCompleted, setIsAudioCompleted] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [bookmarkId, setBookmarkId] = useState(null);
  const [isPracticed, setIsPracticed] = useState("all");
  const [testAttemptedCount, setTestAttemptedCount] = useState(null);
  const [audioURL, setAudioURL] = useState("");
  const [urlLoading, setUrlLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isAudioPlayerDisabled, setIsAudioPlayerDisabled] = useState(false);
  const audioRef = useRef(null);

  const handleSetLoading = (loadingState) => {
    setIsDataLoading(loadingState);
  };

  useEffect(() => {
    if (questionsData) {
      setIsDataLoading(false);
    }
  }, [questionsData]);

  const getSignedURL = async () => {
    const config = {
      method: "get",
      url: `${Base_URL}/app/users/mock-attempted-questions/signed-url`,
    };
    try {
      const response = await axios(config);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      console.error("Error getting signed URL:", error);
      return { error: error.message };
    }
  };

  const uploadAudioFile = async (signedUrl, file) => {
    try {
      const res = await axios.put(signedUrl, file, {
        withCredentials: false,
        headers: {
          "Content-Type": file.type,
        },
      });
      return res.status === 200;
    } catch (error) {
      console.error("Error uploading audio file:", error);
      return false;
    }
  };

  // useEffect(() => {
  //   const handleAudioUpload = async () => {
  //     if (recordedWavFile) {
  //       setUrlLoading(true);
  //       const signedUrlRes = await getSignedURL();
  //       if (signedUrlRes && signedUrlRes.responseCode === 200) {
  //         const uploadSuccess = await uploadAudioFile(signedUrlRes.response, recordedWavFile);

  //         console.log("Signed URL Response: ", signedUrlRes);
  //         console.log("Upload Success Status: ", uploadSuccess);

  //         if (uploadSuccess) {
  //           setAudioURL(`https://swift-uni-user-images.s3.us-east-1.amazonaws.com/${signedUrlRes.key}`);
  //           console.log(`https://swift-uni-user-images.s3.us-east-1.amazonaws.com/${signedUrlRes.key}`);
  //         } else { console.error("Failed to upload audio file");
  //         }
  //       } else {
  //         console.error("Failed to get signed URL");
  //       }
  //       setUrlLoading(false);
  //     }
  //   };

  //   handleAudioUpload();
  // }, [recordedWavFile]);

  const updateLocalQuestionBookmark = (
    testQuestionTableId,
    newBookmarkStatus,
    bookmarkId
  ) => {
    setTestQuestions((prevQuestions) => ({
      ...prevQuestions,
      response: prevQuestions.response.map((question) =>
        question.TestQuestionTableId === testQuestionTableId
          ? {
              ...question,
              IsBookMarked: newBookmarkStatus,
              BookMarkedId: newBookmarkStatus ? bookmarkId : null,
            }
          : question
      ),
    }));
  };

  useEffect(() => {
    const question = testQuestions?.response?.find(
      (q) => q.QuestionId === questionId
    );
    if (question) {
      setIsBookmarked(question.IsBookMarked);
      setBookmarkId(question.BookMarkedId);
      setTestAttemptedCount(question.TestAttemptedCount);
    }
  }, [testQuestions, testQuestionTableId]);

  const handleBookmarkChange = (newIsBookmarked, newBookmarkId) => {
    setIsBookmarked(newIsBookmarked);
    setBookmarkId(newBookmarkId);
  };

  useEffect(() => {
    // console.log("isAudioCompleted in parent: ", isAudioCompleted);
  }, [isAudioCompleted]);

  useEffect(() => {
    // console.log("autoStartRecording in parent: ", autoStartRecording);
  }, [autoStartRecording]);

  const handleAudioCompletion = () => {
    setIsAudioCompleted(true);
  };

  const handleRecordingStart = () => {
    setIsRecordingStarted(true);

    setIsAudioPlayerDisabled(true);
    if (audioRef.current) {
      audioRef.current.pause();
    }
  };

  const handleRecordingStop = () => {
    setIsRecordingStopped(true);
  };

  useEffect(() => {
    if (
      testQuestions.response &&
      testQuestions.response.length > 0 &&
      currentQuestionIndex < testQuestions.response.length
    ) {
      const question = testQuestions.response[currentQuestionIndex];
      setQuestionID(question.QuestionId);
      setTestQuestionTableId(question.TestQuestionTableId);
    }
  }, [testQuestions, currentQuestionIndex]);

  const handleNextQuestion = () => {
    // console.log("Current Index:", currentQuestionIndex);
    // console.log("Total questions on this page:", testQuestions.response?.length);
    // console.log("Current page:", page);
    // console.log("Total pages:", totalPages);

    if (
      currentQuestionIndex <
      (testQuestions.response ? testQuestions.response.length - 1 : 0)
    ) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else if (page < totalPages) {
      setPage(page + 1);
      setCurrentQuestionIndex(0);
    } else {
      console.log("No more questions to display.");
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    } else if (page > 1) {
      setPage(page - 1);
      setCurrentQuestionIndex(testQuestions.response.length - 1);
    } else {
      console.log("No previous questions to display.");
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleMyAttemptedQuestionsScore = (score, openScorecard) => {
    setMyAttemptedQuestionsScore(score);
    if (openScorecard) {
      setShouldOpenScorecard(true);
    }
  };

  useEffect(() => {
    if (shouldOpenScorecard) {
      setScorecardOpen(true);
      setShouldOpenScorecard(false);
    }
  }, [shouldOpenScorecard]);

  const handleSetRecordedWavFile = (file) => {
    setRecordedWavFile(file);
  };

  const handleSetCanSubmit = (status) => {
    setCanSubmit(status);
  };

  // useEffect(() => {
  //   const selectedQuestion = testQuestions?.response?.find(q => q.QuestionId === questionId);
  //   if (selectedQuestion) {
  //     setTotalTestTime(selectedQuestion.TotalTime / 1000);
  //   }
  // }, [questionId, testQuestions]);

  function getQuestionName() {
    const questionTemp = testQuestions?.response?.find(
      (q) => q.QuestionId === questionId
    );
    if (questionTemp) {
      return questionTemp.QuestionName;
    } else {
      return "loading";
    }
  }

  const handleSearchChange = (newSearchTerm) => {
    setSearchTerm(newSearchTerm);
  };

  useEffect(() => {
    setPage(1);
  }, [filterBookmarked, filterPrediction]);

  const fetchData = async () => {
    const encodedQuestionName = encodeURIComponent(questionname);
    const searchQueryParam = searchTerm
      ? `&search_name=${encodeURIComponent(searchTerm)}`
      : "";
    const highFrequencyParam = highFrequency ? `&high_frequency=true` : "";
    let sortDescParam = highFrequency
      ? ""
      : `&order_by=${wantToSortDesc ? "asc" : "desc"}`;
    let isPracticedParam =
      isPracticed === "all" ? "" : `&is_practiced=${isPracticed}`;
    let isPredictionParam = filterPrediction
      ? `&prediction=${filterPrediction}`
      : `&prediction=${false}`;
    let isPteCoreParam = false;

    const queryParams = `test_name=${encodedQuestionName}&page=${page}&bookmarked=${filterBookmarked}&is_ptecore=${isPteCoreParam}${sortDescParam}${highFrequencyParam}${isPracticedParam}${searchQueryParam}${isPredictionParam}`;

    try {
      const url = `${Base_URL}/app/users/test-questions/get-by-name?${queryParams}`;
      const response = await fetch(url, { credentials: "include" });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data.responseCode === 501) {
        logout();
        navigate("/login");
        return;
      }

      setTestQuestions(data);
      if (data.response && data.response.length > 0) {
        setQuestionID(data.response[0].QuestionId);
        setTestQuestionTableId(data.response[0].TestQuestionTableId);
      }

      const totalQuestions = data.totalQuestions || 1;
      const questionsPerPage = 8;
      const calculatedTotalPages = Math.ceil(
        totalQuestions / questionsPerPage
      );
      setTotalPages(calculatedTotalPages);
    } catch (error) {
      console.error("Failed to fetch test questions:", error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    page,
    searchTerm,
    filterBookmarked,
    filterPrediction,
    isPracticed,
    wantToSortDesc,
    highFrequency,
  ]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const scorecardElement = document.getElementById("scorecard");
      if (scorecardElement && !scorecardElement.contains(event.target)) {
        setScorecardOpen(false);
        setElapsedTime(0);
        setEnableSkillsData([]);
        setMyAttemptedQuestionsScore(null);
        setMarksObtained(0);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  // const handleSubmit = async () => {
  //   if (
  //     // selectedAnswers &&
  //     // typeof selectedAnswers === "string" &&
  //     // selectedAnswers.trim() !== "" &&
  //     questionsData &&
  //     recordedWavFile
  //   ) {
  //     setIsLoading(true);
  //     try {
  //       if (recordedWavFile.type !== "audio/wav") {
  //         throw new Error("The file is not a .wav file");
  //       }
  //       const file = new File([recordedWavFile], "audiofile.wav", {
  //         lastModified: Date.now,
  //       });

  //       const formData = new FormData();
  //       formData.append("audiofile", file);
  //       formData.append(
  //         "correct_text",
  //         questionsData?.response?.QuestionStatement
  //       );
  //       formData.append("user_text", selectedAnswers);

  //       const response = await fetch(
  //         `${Base_URL_AiScore}/repeatsentence`,
  //         {
  //           method: "POST",
  //           body: formData,
  //         }
  //       );

  //       const data = await response.json();

  //       if (data) {
  //         setRepeatSentenceScore(data);
  //         setIsSubmitted(true);
  //         setScorecardOpen(true);
  //         setIsScoreDataReadyForSubmit(true);
  //       } else {
  //         logout();
  //         navigate("/login");
  //       }
  //     } catch (error) {
  //       console.error("Failed to fetch essay score:", error);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   }
  // };

  const handleSubmit = async () => {
    setIsLoading(true);

    if (!recordedWavFile) {
      console.error("No audio file to upload");
      setIsLoading(false);
      return;
    }

    const { MajA, MinA } = getAspects(getQuestionName());

    try {
      // Upload the audio first
      const signedUrlRes = await getSignedURL();
      if (!signedUrlRes || signedUrlRes.responseCode !== 200) {
        console.error("Failed to get signed URL");
        setIsLoading(false);
        return;
      }

      const uploadSuccess = await uploadAudioFile(
        signedUrlRes.response,
        recordedWavFile
      );
      if (!uploadSuccess) {
        console.error("Failed to upload audio file");
        setIsLoading(false);
        return;
      }

      setAudioURL(
        `https://swift-uni-user-images.s3.us-east-1.amazonaws.com/${signedUrlRes.key}`
      );
      console.log(
        `https://swift-uni-user-images.s3.us-east-1.amazonaws.com/${signedUrlRes.key}`
      );

      if (questionsData && recordedWavFile) {
        const file = new File([recordedWavFile], "audiofile.wav", {
          type: "audio/wav",
          lastModified: new Date(),
        });

        // let MajA = ["households", "household", "pets"];
        // let MinA = ["cats", "dogs", "birds"];

        console.log("major_aspects", JSON.stringify(MajA));
        console.log("minor_aspects", JSON.stringify(MinA));

        const formData = new FormData();
        formData.append("audiofile", file);
        formData.append("user_text", selectedAnswers);
        // formData.append("major_aspects", JSON.stringify(MajA));
        // formData.append("minor_aspects", JSON.stringify(MinA));
        formData.append("major_aspects", JSON.stringify(MajA));
        formData.append("minor_aspects", JSON.stringify(MinA));

        const response = await fetch(`${Base_URL_AiScore}/respondtosituation`, {
          method: "POST",
          body: formData,
        });

        const data = await response.json();

        if (response.ok && data) {
          setRepeatSentenceScore(data);
          setIsSubmitted(true);
          setScorecardOpen(true);
          setIsScoreDataReadyForSubmit(true);
        } else {
          logout();
          navigate("/login");
        }
      }
    } catch (error) {
      console.error("Submission error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    async function submitScoreData() {
      const userResponse = {
        correctAnswers: questionsData?.response?.QuestionStatement,
        selectedAnswers: selectedAnswers,
        repeatSentenceScore: repeatSentenceScore,
        audioURLSaved: audioURL,
      };

      const payload = {
        test_question_id: testQuestionTableId,
        marks_obtained:
          repeatSentenceScore.content_score +
          repeatSentenceScore.pronunciation_score +
          repeatSentenceScore.fluency_score,
        user_response: JSON.stringify(userResponse),
        time_taken: elapsedTime,
        is_ptecore: false,
      };

      try {
        const response = await fetch(
          `${Base_URL}/app/users/attempted-questions/add`,
          {
            credentials: "include",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
        const data = await response.json();
        if (data.responseCode === 200) {
          setScoreDataSubmitted(true);
          setIsScoreDataReadyForSubmit(false);
          setLastScoreUpdate(Date.now());
          fetchData();
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error("Failed to submit score data:", error);
      }
    }

    const shouldSubmitScore =
      isScoreDataReadyForSubmit &&
      !scoreDataSubmitted &&
      elapsedTime &&
      audioURL;
    if (shouldSubmitScore) {
      submitScoreData();
    }
  }, [isScoreDataReadyForSubmit, scoreDataSubmitted, elapsedTime, audioURL]);

  const resetState = () => {
    setSelectedAnswers([]);
    setShowAnswer(false);
    setScorecardOpen(false);
    setCanSubmit(false);
    setElapsedTime(0);
    setIsSubmitted(false);
    setTotalTestTime(totalTestTime);
    setEnableSkillsData([]);
    setScoreDataSubmitted(false);
    setMyAttemptedQuestionsScore(null);
    setMarksObtained(0);
    setShouldOpenScorecard(false);
    setResetTrigger((prevState) => !prevState);
    setRecordedAudio("");
    setIsRecordingStopped(false);
    setIsRecordingStarted(false);
    setIsAudioCompleted(false);
    setAudioURL("");
    setIsAudioPlayerDisabled(false);
    setIsAudioPlayerDisabled(false);
  };

  useEffect(() => {
    resetState();
  }, [questionId]);

  const handleRedo = () => {
    resetState();
  };

  const calculateDaysDifference = (createdAt) => {
    const currentDate = new Date();
    const createdDate = new Date(createdAt);
    const timeDiff = currentDate - createdDate;
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    return daysDiff < 60;
  };

  return (
    <>
      {isDataLoading && <LoadingModal />}
      <div id="scorecardd">
        {scorecardOpen && (
          <Modal isOpen={scorecardOpen} style={modalStyle}>
            <AiScorePopupSpeakingRS
              key={AiScorePopupSpeakingData[0].key}
              // EnableSkills={AiScorePopupSpeakingData[0].EnableSkills}
              SmallScoreCard={AiScorePopupSpeakingData[0].SmallScoreCard}
              enableSkillsScore={
                myAttemptedQuestionsScore
                  ? JSON.parse(myAttemptedQuestionsScore.UsersResponse)
                      .repeatSentenceScore
                  : repeatSentenceScore
              }
              allData={
                myAttemptedQuestionsScore
                  ? JSON.parse(myAttemptedQuestionsScore.UsersResponse)
                      .repeatSentenceScore
                  : repeatSentenceScore
              }
              UserResponse={
                myAttemptedQuestionsScore
                  ? JSON.parse(myAttemptedQuestionsScore.UsersResponse)
                      .selectedAnswers
                  : selectedAnswers
              }
              recordedAudio={
                myAttemptedQuestionsScore
                  ? JSON.parse(myAttemptedQuestionsScore.UsersResponse)
                      .audioURLSaved
                  : audioURL
              }
              OriginalText={questionsData.response.QuestionStatement}
              elapsedTime={
                myAttemptedQuestionsScore
                  ? myAttemptedQuestionsScore.TimeTaken
                  : elapsedTime
              }
              close={setScorecardOpen}
              two={true}
            />
          </Modal>
        )}
      </div>
      {isSidePanelOpen && <SidePannelBackdrop isOpen={isSidePanelOpen} />}
      <SidePannel
        onToggle={setIsSidePanelOpen}
        testQuestions={testQuestions.response || []}
        totalTestQuestions={testQuestions.totalQuestions}
        heading={"Respond to a Situation"}
        setQuestionsData={setQuestionsData}
        questionID={questionId}
        setQuestionID={setQuestionID}
        setSelectedAnswers={setSelectedAnswers}
        setTriggerReset={setResetTrigger}
        logo={Logo}
        setTestQuestionTableId={setTestQuestionTableId}
        testQuestionTableId={testQuestionTableId}
        handleSearchChange={handleSearchChange}
        onPageChange={handlePageChange}
        currentPage={page}
        totalPages={totalPages}
        setFilterBookmarked={setFilterBookmarked}
        setFilterPrediction={setFilterPrediction}
        setWantToSortDesc={setWantToSortDesc}
        setHighFrequency={setHighFrequency}
        updateLocalQuestionBookmark={updateLocalQuestionBookmark}
        setIsPracticed={setIsPracticed}
        setDataLoading={handleSetLoading}
        setCurrentQuestionIndex={setCurrentQuestionIndex}
        fetchData={fetchData}
      />
      <Navbar />
      <FlexDiv style={{ width: "100%" }}>
        <FlexDiv
          style={{ flexDirection: "column", width: "100%", maxWidth: "1880px" }}
        >
          <TestHeadingReTellLec
            logo={Logo}
            heading={"Respond to a Situation"}
            subheading={S_RTAS_Subheading}
            serialNo={questionId ? "#" + questionId : "#000"}
            questionName={getQuestionName()}
            isSubmitted={isSubmitted}
            // remainTime={false}
            setElapsedTime={setElapsedTime}
            triggerReset={resetTrigger}
            testQuestionTableId={testQuestionTableId}
            appearedCount={questionsData?.response?.AppearedCount}
            IsBookMarked={isBookmarked}
            BookMarkedId={bookmarkId}
            onBookmarkChange={handleBookmarkChange}
            questionID={questionId}
            dictionaryText={questionsData?.response?.QuestionStatement}
            onCountdownComplete={() => setAutoStartRecording(true)}
            isSpeakingTest={true}
            isRecordingStarted={isRecordingStarted}
            isRecordingStopped={isRecordingStopped}
            preTestCountdownTime="3"
            isAudioPlayerComponent={true}
            isAudioCompleted={isAudioCompleted}
            testAttemptedCount={testAttemptedCount}
            isReTellLecture={true}
            reTellLecturePostAudioCountdown={20}
            isPrediction={questionsData?.response?.Prediction}
            isNew={calculateDaysDifference(questionsData?.response?.CreatedAt)}
          />

          <SWT_QCard_Div>
            {questionsData && questionsData?.response && (
              <AudioPlayer
                AudioObjects={questionsData.response.AudioObjects}
                onAudioComplete={handleAudioCompletion}
                resetTrigger={resetTrigger}
                isAudioPlayerDisabled={isAudioPlayerDisabled}
                audioRef={audioRef}
              />
            )}
          </SWT_QCard_Div>

          <SWT_QCard_Div>
            {questionsData && questionsData?.response && (
              <QuestionCard
                id={questionId}
                textValue={questionsData.response.QuestionStatement}
              />
            )}
          </SWT_QCard_Div>

          <SWT_MCQCard_Div>
            <SpeakingMicCard
              key={`reset-recording-component-${resetTrigger}`}
              setSelectedAnswers={setSelectedAnswers}
              resetTrigger={resetTrigger}
              setCanSubmit={handleSetCanSubmit}
              setRecordedAudio={setRecordedAudio}
              setRecordedWavFile={handleSetRecordedWavFile}
              autoStartRecording={autoStartRecording}
              resetAutoStart={() => setAutoStartRecording(false)}
              handleRecordingStop={handleRecordingStop}
              handleRecordingStart={handleRecordingStart}
              totalTestTime={totalTestTime}
              isAudioPlayerComponent={true}
              isAudioCompleted={isAudioCompleted}
              isReTellLecture={true}
              postAudioPlayTestCountdownTime={20000}
            />
          </SWT_MCQCard_Div>

          <ButtonList
            onSubmit={() => {
              handleSubmit();
            }}
            onRedo={() => handleRedo()}
            onAnswer={() => setShowAnswer(!showAnswer)}
            // canSubmit={canSubmit}
            isLoading={isLoading}
            urlLoading={urlLoading}
            canSubmit={() => !canSubmit}
            isSubmitted={isSubmitted}
            onNext={handleNextQuestion}
            onPrevious={handlePreviousQuestion}
            hasPrevious={currentQuestionIndex > 0 || page > 1}
            hasNext={
              currentQuestionIndex <
                (testQuestions.response
                  ? testQuestions.response.length - 1
                  : 0) || page < totalPages
            }
            handleSearchChange={handleSearchChange}
          />
          {showAnswer && questionsData?.response?.QuestionStatement && (
            <ShowScriptBox
              answerText={questionsData?.response?.QuestionStatement}
            />
          )}
          <CommunityScore
            testQuestionTableId={testQuestionTableId}
            onSelectMyScore={handleMyAttemptedQuestionsScore}
            lastScoreUpdate={lastScoreUpdate}
            renderDownloadIcon={true}
            ScoreLetter="S"
            bg="#49D7F2"
            totalScore="13.00"
            testSubmissionInProcess={isLoading}
          />
        </FlexDiv>
      </FlexDiv>
    </>
  );
};

export default SpeakingRS;

// import React, { useEffect, useState } from "react";
// import TestHeading from "../components/Common/TestHeading";
// import Logo from "../assets/images/S_RTAS_Logo.svg";
// import { S_RTAS_Subheading } from "../components/Common/Data";
// import Navbar from "../components/Navbar/Navbar";
// import ButtonList from "../components/Common/ButtonList";
// import CommunityScore from "../components/Common/CommunityScore";
// import { SWT_MCQCard_Div, SWT_QCard_Div } from "./Style";
// import { SpeakingRTASData } from "../components/Speaking/data";
// import SpeakingMicCard from "../components/Speaking/SpeakingMicCard";
// import AudioPlayer from "../components/Speaking/AudioPlayer";
// import QuestionCard from "../components/Speaking/QuestionCard";
// import { FlexDiv } from "../assets/styles/style";
// import { SidePannelBackdrop } from "../components/Common/Style";

// const SpeakingRTAS = () => {
//   const [testQuestions, setTestQuestions] = useState([]);
//   const { logout } = useAuth();
//   const navigate = useNavigate();
//   const [questionsData, setQuestionsData] = useState(null);
//   const [showAnswer, setShowAnswer] = useState(false);
//   const [questionId, setQuestionID] = useState();
//   const [testQuestionTableId, setTestQuestionTableId] = useState();
//   const [scorecardOpen, setScorecardOpen] = useState(false);
//   const [selectedAnswers, setSelectedAnswers] = useState([""]);
//   const [recordedAudio, setRecordedAudio] = useState("");
//   const [canSubmit, setCanSubmit] = useState(false);
//   const [elapsedTime, setElapsedTime] = useState(0);
//   const [triggerReset, setTriggerReset] = useState(0);
//   const [totalTestTime, setTotalTestTime] = useState(40);
//   const [isSubmitted, setIsSubmitted] = useState(false);
//   const [myAttemptedQuestionsScore, setMyAttemptedQuestionsScore] =
//     useState(null);
//   const [communityAttemptedQuestionsData, setCommunityAttemptedQuestionsData] =
//     useState(null);
//   const [marksObtained, setMarksObtained] = useState(0);
//   const [correctAnswers, setCorrectAnswers] = useState([""]);
//   const [shouldOpenScorecard, setShouldOpenScorecard] = useState(false);
//   const [isScoreDataReadyForSubmit, setIsScoreDataReadyForSubmit] =
//     useState(false);
//   const [lastScoreUpdate, setLastScoreUpdate] = useState(Date.now());
//   const [searchTerm, setSearchTerm] = useState("");
//   const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);

//   return (
//     <>
//       <Navbar />
//       <FlexDiv style={{ width: "100%" }}>
//         <FlexDiv
//           style={{ flexDirection: "column", width: "100%", maxWidth: "1880px" }}
//         >
//           <TestHeading
//             logo={Logo}
//             heading={"Respond to a Situation"}
//             subheading={S_RTAS_Subheading}
//             serialNo={"#21000020"}
//             questionName={"Damaged Camera"}
//             // remainTime={false}
//             //new
//             // addDictionary={false}
//             // appearedCount={questionsData?.response?.AppearedCount}
//             // IsBookMarked={questionsData?.response?.IsBookMarked}
//             // BookMarkedId={questionsData?.response?.BookMarkedId}
//             // questionID={questionId}
//           />

//           <SWT_QCard_Div>
//             <QuestionCard
//               id={SpeakingRTASData[0].id}
//               textValue={SpeakingRTASData[0].textValue}
//             />
//           </SWT_QCard_Div>

//           <SWT_QCard_Div>
//             <AudioPlayer />
//           </SWT_QCard_Div>

//           <SWT_MCQCard_Div>
//             <SpeakingMicCard />
//           </SWT_MCQCard_Div>

//           <ButtonList />
//           <CommunityScore />
//         </FlexDiv>
//       </FlexDiv>
//     </>
//   );
// };

// export default SpeakingRTAS;
