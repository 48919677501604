import React, { useEffect, useState, useRef } from "react";
import TestHeading from "../components/Common/TestHeading";
import LSMWLogo from "../assets/images/L_SMW_Logo.svg";
import { L_SMW_Subheading } from "../components/Common/Data";
import Navbar from "../components/Navbar/Navbar";
import ButtonList from "../components/Common/ButtonList";
import CommunityScore from "../components/Common/CommunityScore";
import { SWT_MCQCard_Div, SWT_QCard_Div } from "./Style";
import { MCQsComponentData } from "../components/Writing/data";
import MCQsComponent from "../components/Writing/MCQsComponent";
import AudioPlayer from "../components/Speaking/AudioPlayer";
import { FlexDiv } from "../assets/styles/style";
import SidePannel from "../components/Common/SidePannel";
import ReadingAnswerBox from "../components/Common/ReadingAnswerBox";
import ScorePopupReadingMCM from "../components/Reading/ScorePopupReadingMCM";
import { AiScorePopupListeningSMWData } from "../components/Listening/data";
import { useAuth } from "../authentication/Auth";
import ShowScriptBox from "../components/Common/ShowScriptBox";
import { SidePannelBackdrop } from "../components/Common/Style";
import LoadingModal from "../components/Common/LoadingModal";
import { Base_URL } from "../Client/apiURL";
import { getSuggestion } from "../components/Reading/data";

const questionname = "Select Missing Word";

const ListeningSMW = () => {
  const [testQuestions, setTestQuestions] = useState([]);
  const [questionId, setQuestionID] = useState();
  const [questionsData, setQuestionsData] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [showAnswer, setShowAnswer] = useState(false);
  const [showScript, setShowScript] = useState(false);
  const [scorecardOpen, setScorecardOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [triggerReset, setTriggerReset] = useState(false);
  const { logout } = useAuth();
  const [enableSkillsData, setEnableSkillsData] = useState([]);
  const [scoreDataSubmitted, setScoreDataSubmitted] = useState(false);
  const [testQuestionTableId, setTestQuestionTableId] = useState();
  const [myAttemptedQuestionsScore, setMyAttemptedQuestionsScore] =
    useState(null);
  const [communityAttemptedQuestionsData, setCommunityAttemptedQuestionsData] =
    useState(null);
  const [marksObtained, setMarksObtained] = useState(0);
  const [correctAnswers, setCorrectAnswers] = useState([""]);
  const [shouldOpenScorecard, setShouldOpenScorecard] = useState(false);
  const [isScoreDataReadyForSubmit, setIsScoreDataReadyForSubmit] =
    useState(false);
  const [lastScoreUpdate, setLastScoreUpdate] = useState(Date.now());
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [filterBookmarked, setFilterBookmarked] = useState(false);
  const [filterPrediction, setFilterPrediction] = useState(false);
  const [wantToSortDesc, setWantToSortDesc] = useState(false);
  const [highFrequency, setHighFrequency] = useState(false);
  const [isPracticed, setIsPracticed] = useState("all");
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [bookmarkId, setBookmarkId] = useState(null);
  const [testAttemptedCount, setTestAttemptedCount] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isAudioPlayerDisabled, setIsAudioPlayerDisabled] = useState(false);
  const audioRef = useRef(null);

  const handleSetLoading = (loadingState) => {
    setIsDataLoading(loadingState);
  };

  useEffect(() => {
    if (questionsData) {
      setIsDataLoading(false);
    }
  }, [questionsData]);

  const updateLocalQuestionBookmark = (
    testQuestionTableId,
    newBookmarkStatus,
    bookmarkId
  ) => {
    setTestQuestions((prevQuestions) => ({
      ...prevQuestions,
      response: prevQuestions.response.map((question) =>
        question.TestQuestionTableId === testQuestionTableId
          ? {
              ...question,
              IsBookMarked: newBookmarkStatus,
              BookMarkedId: newBookmarkStatus ? bookmarkId : null,
            }
          : question
      ),
    }));
  };

  useEffect(() => {
    const question = testQuestions?.response?.find(
      (q) => q.QuestionId === questionId
    );
    if (question) {
      setIsBookmarked(question.IsBookMarked);
      setBookmarkId(question.BookMarkedId);
      setTestAttemptedCount(question.TestAttemptedCount);
    }
  }, [testQuestions, testQuestionTableId]);

  const handleBookmarkChange = (newIsBookmarked, newBookmarkId) => {
    setIsBookmarked(newIsBookmarked);
    setBookmarkId(newBookmarkId);
  };

  useEffect(() => {
    if (
      testQuestions.response &&
      testQuestions.response.length > 0 &&
      currentQuestionIndex < testQuestions.response.length
    ) {
      const question = testQuestions.response[currentQuestionIndex];
      setQuestionID(question.QuestionId);
      setTestQuestionTableId(question.TestQuestionTableId);
    }
  }, [testQuestions, currentQuestionIndex]);

  const handleNextQuestion = () => {
    // console.log("Current Index:", currentQuestionIndex);
    // console.log("Total questions on this page:", testQuestions.response?.length);
    // console.log("Current page:", page);
    // console.log("Total pages:", totalPages);

    if (
      currentQuestionIndex <
      (testQuestions.response ? testQuestions.response.length - 1 : 0)
    ) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else if (page < totalPages) {
      setPage(page + 1);
      setCurrentQuestionIndex(0);
    } else {
      console.log("No more questions to display.");
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    } else if (page > 1) {
      setPage(page - 1);
      setCurrentQuestionIndex(testQuestions.response.length - 1);
    } else {
      console.log("No previous questions to display.");
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleMyAttemptedQuestionsScore = (score, openScorecard) => {
    setMyAttemptedQuestionsScore(score);
    if (openScorecard) {
      setShouldOpenScorecard(true);
    }
  };

  useEffect(() => {
    if (shouldOpenScorecard) {
      setScorecardOpen(true);
      setShouldOpenScorecard(false);
    }
  }, [shouldOpenScorecard]);

  useEffect(() => {
    if (
      questionsData &&
      questionsData.response.OptionNames &&
      questionsData.response.AnswerNames
    ) {
      const correct = findMatchingLetters(
        questionsData.response.OptionNames,
        questionsData.response.AnswerNames
      );
      setCorrectAnswers(correct);
    }
  }, [questionsData]);

  function getQuestionName() {
    const questionTemp = testQuestions?.response?.find(
      (q) => q.QuestionId === questionId
    );
    if (questionTemp) {
      return questionTemp.QuestionName;
    } else {
      return "loading";
    }
  }

  const findMatchingLetters = (bigArray, smallArray) => {
    const matchingLetters = [];

    for (let i = 0; i < bigArray.length; i++) {
      if (smallArray.includes(bigArray[i])) {
        matchingLetters.push(String.fromCharCode(65 + i));
      }
    }
    return matchingLetters;
  };

  function canSubmit() {
    return !(
      Array.isArray(selectedAnswers) &&
      selectedAnswers?.length > 0 &&
      selectedAnswers.some((value) => value !== "")
    );
  }

  function markingFunction(answerList, userAnswers) {
    let score = 0;
    answerList.forEach((correctAnswer) => {
      const isCorrect = userAnswers.includes(correctAnswer);
      if (isCorrect) {
        score += 1;
      }
    });
    userAnswers.forEach((answer) => {
      const isCorrect = answerList.includes(answer);
      if (!isCorrect) {
        score = Math.max(0, score - 1);
      }
    });
    return score;
  }

  const handleSearchChange = (newSearchTerm) => {
    setSearchTerm(newSearchTerm);
  };

  useEffect(() => {
    setPage(1);
  }, [filterBookmarked, filterPrediction]);

  const fetchData = async () => {
    const encodedQuestionName = encodeURIComponent(questionname);
    const searchQueryParam = searchTerm ? `&search_name=${encodeURIComponent(searchTerm)}` : "";
    const highFrequencyParam = highFrequency ? `&high_frequency=true` : "";
    let sortDescParam = highFrequency ? "" : `&order_by=${wantToSortDesc ? "asc" : "desc"}`;
    let isPracticedParam = isPracticed === "all" ? "" : `&is_practiced=${isPracticed}`;
    let isPredictionParam = filterPrediction ? `&prediction=${filterPrediction}` : `&prediction=${false}`;
    let isPteCoreParam = false;
   
    const queryParams = `test_name=${encodedQuestionName}&page=${page}&bookmarked=${filterBookmarked}&is_ptecore=${isPteCoreParam}${sortDescParam}${highFrequencyParam}${isPracticedParam}${searchQueryParam}${isPredictionParam}`;

    try {
      const url = `${Base_URL}/app/users/test-questions/get-by-name?${queryParams}`;
      const response = await fetch(url, { credentials: "include" });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data.responseCode === 501) {
        logout();
        navigate("/login");
        return;
      }
      setTestQuestions(data);
      setQuestionID(data.response[0].QuestionId);
      setTestQuestionTableId(data.response[0].TestQuestionTableId);
      const totalQuestions = data.totalQuestions || 1;
      const questionsPerPage = 8;
      const calculatedTotalPages = Math.ceil(
        totalQuestions / questionsPerPage
      );
      setTotalPages(calculatedTotalPages);
    } catch (error) {
      console.error("Failed to fetch test questions:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    page,
    searchTerm,
    filterBookmarked,
    filterPrediction,
    isPracticed,
    wantToSortDesc,
    highFrequency,
  ]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const scorecardElement = document.getElementById("scorecard");
      if (scorecardElement && !scorecardElement.contains(event.target)) {
        setScorecardOpen(false);
        setElapsedTime(0);
        setEnableSkillsData([]);
        setMyAttemptedQuestionsScore(null);
        setMarksObtained(0);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    async function submitScoreData() {
      const userResponse = {
        correctAnswers: correctAnswers,
        selectedAnswers: selectedAnswers,
        enableSkillsData: enableSkillsData,
      };

      const payload = {
        test_question_id: testQuestionTableId,
        marks_obtained: marksObtained,
        user_response: JSON.stringify(userResponse),
        time_taken: elapsedTime,
        is_ptecore: false,
      };

      try {
        const response = await fetch(
          `${Base_URL}/app/users/attempted-questions/add`,
          {
            credentials: "include",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
        const data = await response.json();
        if (data.responseCode === 200) {
          setScoreDataSubmitted(true);
          setIsScoreDataReadyForSubmit(false);
          setLastScoreUpdate(Date.now());
          fetchData();
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error("Failed to submit score data:", error);
      }
    }

    const shouldSubmitScore =
      isScoreDataReadyForSubmit &&
      enableSkillsData.length > 0 &&
      !scoreDataSubmitted;
    if (shouldSubmitScore) {
      submitScoreData();
    }
  }, [isScoreDataReadyForSubmit, enableSkillsData.length, scoreDataSubmitted]);

  const resetState = () => {
    setSelectedAnswers([]);
    setShowAnswer(false);
    setShowScript(false);
    setScorecardOpen(false);
    setIsSubmitted(false);
    setElapsedTime(0);
    setTriggerReset((prevState) => !prevState);
    setEnableSkillsData([]);
    setScoreDataSubmitted(false);
    setMyAttemptedQuestionsScore(null);
    setMarksObtained(0);
    setShouldOpenScorecard(false);
    setIsAudioPlayerDisabled(false);
  };

  useEffect(() => {
    resetState();
  }, [questionId]);

  const handleRedo = () => {
    resetState();
  };

  function handleSubmission() {
    setIsSubmitted(true);
    setScorecardOpen(true);
    setIsScoreDataReadyForSubmit(true);
    setIsAudioPlayerDisabled(true);
    if (audioRef.current) {
      audioRef.current.pause();
    }
  }

  const calculateDaysDifference = (createdAt) => {
    const currentDate = new Date();
    const createdDate = new Date(createdAt);
    const timeDiff = currentDate - createdDate;
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    return daysDiff < 60;
  };

  return (
    <>
      {isDataLoading && <LoadingModal />}
      {scorecardOpen && (
        <FlexDiv
          style={{
            position: "fixed",
            top: " 50%",
            left: " 50%",
            transform: "translate(-50%, -50%)",
            zIndex: "1002",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            width: "100vw",
            height: "100vh",
          }}
        >
          <ScorePopupReadingMCM
            EnableSkills={[
              {
                component: "Choice",
                score: `${markingFunction(findMatchingLetters(questionsData?.response.OptionNames, questionsData?.response.AnswerNames), selectedAnswers)} / 1`,
                suggestion: getSuggestion( markingFunction(findMatchingLetters(questionsData?.response.OptionNames, questionsData?.response.AnswerNames), selectedAnswers), 1 ),
              },
            ]}
            SmallScoreCard={AiScorePopupListeningSMWData[0].SmallScoreCard}
            CorrectAnswers={findMatchingLetters(
              questionsData?.response.OptionNames,
              questionsData?.response.AnswerNames
            )}
            selectedAnswers={selectedAnswers}
            answerIsAList={false}
            markingFunction={markingFunction}
            elapsedTime={elapsedTime}
            setEnableSkillsData={setEnableSkillsData}
            myAttemptedQuestionsScore={myAttemptedQuestionsScore}
            setMarksObtained={setMarksObtained}
            questionOptions={questionsData?.response.OptionNames}
            close={setScorecardOpen}
          />
        </FlexDiv>
      )}
      {isSidePanelOpen && <SidePannelBackdrop isOpen={isSidePanelOpen} />}
      <SidePannel
        onToggle={setIsSidePanelOpen}
        testQuestions={testQuestions.response || []}
        totalTestQuestions={testQuestions.totalQuestions}
        heading={"Select Missing Word"}
        setQuestionsData={setQuestionsData}
        questionID={questionId}
        setQuestionID={setQuestionID}
        setSelectedAnswers={setSelectedAnswers}
        setTriggerReset={setTriggerReset}
        logo={LSMWLogo}
        setTestQuestionTableId={setTestQuestionTableId}
        testQuestionTableId={testQuestionTableId}
        handleSearchChange={handleSearchChange}
        onPageChange={handlePageChange}
        currentPage={page}
        totalPages={totalPages}
        updateLocalQuestionBookmark={updateLocalQuestionBookmark}
        setFilterBookmarked={setFilterBookmarked}
        setFilterPrediction={setFilterPrediction}
        setWantToSortDesc={setWantToSortDesc}
        setHighFrequency={setHighFrequency}
        setIsPracticed={setIsPracticed}
        setDataLoading={handleSetLoading}
        setCurrentQuestionIndex={setCurrentQuestionIndex}
        fetchData={fetchData}
      />
      <Navbar />
      <FlexDiv style={{ width: "100%" }}>
        <FlexDiv
          style={{ flexDirection: "column", width: "100%", maxWidth: "1880px" }}
        >
          <TestHeading
            logo={LSMWLogo}
            heading={"Select Missing Word"}
            subheading={L_SMW_Subheading}
            serialNo={questionId ? "#" + questionId : "#000"}
            questionName={getQuestionName()}
            remainTime={false}
            onTestSubmit={handleSubmission}
            isSubmitted={isSubmitted}
            setElapsedTime={setElapsedTime}
            triggerReset={triggerReset}
            testQuestionTableId={testQuestionTableId}
            appearedCount={questionsData?.response?.AppearedCount}
            IsBookMarked={isBookmarked}
            BookMarkedId={bookmarkId}
            onBookmarkChange={handleBookmarkChange}
            questionID={questionId}
            // dictionaryText={questionsData?.response?.QuestionStatement}
            dictionaryArrayText={questionsData?.response?.OptionNames}
            testAttemptedCount={testAttemptedCount}
            isPrediction={questionsData?.response?.Prediction}
            isNew={calculateDaysDifference(questionsData?.response?.CreatedAt)}
          />
          {questionsData && questionsData.response && (
            <SWT_QCard_Div>
              <AudioPlayer
                AudioObjects={questionsData.response.AudioObjects}
                resetTrigger={triggerReset}
                isAudioPlayerDisabled={isAudioPlayerDisabled}
                audioRef={audioRef}
              />
            </SWT_QCard_Div>
          )}
          <SWT_MCQCard_Div>
            <MCQsComponent
              key={`mcq-component-${triggerReset}`}
              id={questionId}
              question={questionsData?.response?.OptionText}
              answers={
                questionsData?.response?.OptionNames
                  ? questionsData?.response?.OptionNames.map(
                      (item, index) =>
                        `${String.fromCharCode(65 + index)}) ${item}`
                    )
                  : []
              }
              render={MCQsComponentData[1].render}
              userSelectedAnswers={setSelectedAnswers}
            />
          </SWT_MCQCard_Div>
          <ButtonList
            onSubmit={() => {
              handleSubmission();
            }}
            onRedo={() => handleRedo()}
            canSubmit={canSubmit}
            isSubmitted={isSubmitted}
            renderScript={true}
            onAnswer={() => setShowAnswer(!showAnswer)}
            onScript={() => setShowScript(!showScript)}
            onNext={handleNextQuestion}
            onPrevious={handlePreviousQuestion}
            hasPrevious={currentQuestionIndex > 0 || page > 1}
            hasNext={
              currentQuestionIndex <
                (testQuestions.response
                  ? testQuestions.response.length - 1
                  : 0) || page < totalPages
            }
            handleSearchChange={handleSearchChange}
          />
          {showAnswer && questionsData?.response?.AnswerNames && (
            <ReadingAnswerBox
              answerText={findMatchingLetters(
                questionsData?.response.OptionNames,
                questionsData?.response.AnswerNames
              )}
              addIndex={false}
            />
          )}
          {showScript && questionsData?.response?.QuestionStatement && (
            <ShowScriptBox
              answerText={questionsData?.response?.QuestionStatement}
            />
          )}
          <CommunityScore
            ScoreLetter="L"
            bg="#868EAF"
            formateScore={false}
            totalScore="1"
            ai_score={false}
            testQuestionTableId={testQuestionTableId}
            onSelectMyScore={handleMyAttemptedQuestionsScore}
            lastScoreUpdate={lastScoreUpdate}
          />
        </FlexDiv>
      </FlexDiv>
    </>
  );
};

export default ListeningSMW;
