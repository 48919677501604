import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { FlexDiv } from "../../assets/styles/style";
import {
  AnalyticsHeader,
  FeedbackHeaderTitle,
  WhiteDiv,
  CircleDiv,
  CircleScoresDiv,
  PointImg,
  ScoreDigit,
  PointAndScore,
  ImgAndLine,
} from "./style";

import ReadingImg from "../../assets/todaytaskcard/todaytaskimg1.svg";
import ListeningImg from "../../assets/todaytaskcard/todaytaskimg2.svg";
import WritingImg from "../../assets/todaytaskcard/todaytaskimg3.svg";
import SpeakingImg from "../../assets/todaytaskcard/todaytaskimg4.svg";
import DividerLine from "../../assets/images/dividersvg.svg";
import ReadingPoint from "../../assets/images/readingpoint.svg";
import WritingPoint from "../../assets/images/writingpoint.svg";
import ListeningPoint from "../../assets/images/listeningpoint.svg";
import SpeakingPoint from "../../assets/images/speakingpoint.svg";

import AnalyticsTestCard from "./AnalyticsTestCard";
// import AnalyticsCircle from "./AnalyticsCircle";
import AnalyticsForMtScore from "./AnalyticsForMtScore";
import AnalyticsTestCard2 from "./AnalyticsTestCard2";

import axios from "axios";
import { useParams } from "react-router-dom";
import LoadingModal from "../Common/LoadingModal";
import { PieChart } from "react-minimal-pie-chart";
import { Base_URL } from "../../Client/apiURL";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { fetchMockTestScore } from "./helperFunctions";

function convertSecondsToMMSS(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return isNaN(formattedSeconds)
    ? "00:00"
    : `${formattedMinutes}:${formattedSeconds}`;
}

const SpeakingQuestionNames = [
  "Read Aloud",
  "Repeat Sentence",
  "Describe Image",
  "Re-tell Lecture",
  "Answer Short Question",
];

const WritingQuestionNames = ["Summarize Written Text", "Write Essay"];

const ReadingQuestionNames = [
  "Reading & Writing: Fill in the Blanks",
  "Multiple Choice, Multiple Answers",
  "Re-order Paragraphs",
  "Reading: Fill in the Blanks",
  "Multiple Choice, Single Answer",
];

const ListeningQuestionNames = [
  "Summarize Spoken Text",
  "Listening: Multiple Choice, Multiple Answers",
  "Listening: Fill in the Blanks",
  "Highlight Correct Summary",
  "Listening: Multiple Choice, Single Answer",
  "Select Missing Word",
  "Highlight Incorrect Words",
  "Write from Dictation",
];

const ListenQuestionNames = [
  "Summarize Spoken Text",
  "Multiple Choice, Multiple Answers",
  "Fill in the Blanks",
  "Highlight Correct Summary",
  "Multiple Choice, Single Answer",
  "Select Missing Word",
  "Highlight Incorrect Words",
  "Write from Dictation",
];

const categoryOrder = ["Speaking", "Writing", "Reading", "Listening"];
const subCategoryOrder = {
  Speaking: ["Read Aloud", "Repeat Sentence", "Describe Image", "Re-tell Lecture", "Respond to a situation", "Answer Short Question"],
  Writing: ["Summarize Written Text", "Write Essay", "Write Email"],
  Reading: ["Reading & Writing: Fill in the Blanks", "Multiple Choice, Multiple Answers", "Re-order Paragraphs", "Reading: Fill in the Blanks", "Multiple Choice, Single Answer"],
  Listening: ["Summarize Spoken Text", "Listening: Multiple Choice, Multiple Answers", "Fill in the Blanks", "Highlight Correct Summary", "Listening: Multiple Choice, Single Answer", "Select Missing Word", "Highlight Incorrect Words", "Write from Dictation"],
};


  // Helper function to sort responses
  const sortResponses = (responses, categoryOrder, subCategoryOrder) => {
    return responses.sort((a, b) => {
      // Handle missing Category or SubCategory
      const categoryA = a.Category || "";
      const categoryB = b.Category || "";
      const subCategoryA = a.SubCategory || "";
      const subCategoryB = b.SubCategory || "";
  
      // Determine the order index for categories
      const categoryIndexA = categoryOrder.indexOf(categoryA);
      const categoryIndexB = categoryOrder.indexOf(categoryB);
  
      // Categories not in categoryOrder are placed at the end
      const finalCategoryIndexA = categoryIndexA !== -1 ? categoryIndexA : categoryOrder.length;
      const finalCategoryIndexB = categoryIndexB !== -1 ? categoryIndexB : categoryOrder.length;
  
      if (finalCategoryIndexA < finalCategoryIndexB) return -1;
      if (finalCategoryIndexA > finalCategoryIndexB) return 1;
  
      // If categories are the same, sort by subcategory
      if (categoryA === categoryB) {
        const subOrder = subCategoryOrder[categoryA] || [];
        const subIndexA = subOrder.indexOf(subCategoryA);
        const subIndexB = subOrder.indexOf(subCategoryB);
  
        const finalSubIndexA = subIndexA !== -1 ? subIndexA : subOrder.length;
        const finalSubIndexB = subIndexB !== -1 ? subIndexB : subOrder.length;
  
        if (finalSubIndexA < finalSubIndexB) return -1;
        if (finalSubIndexA > finalSubIndexB) return 1;
      }
  
      return 0;
    });
  };
  

const AnalyticsForTeacher = () => {
  const isTab = useMediaQuery("(max-width:1000px)");
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const { userId, mockTestAttemptedId, typeOfTest } = useParams();
  const [mockTestsResult, setMockTestsResult] = useState(null);
  const [listeningTests, setListeningTests] = useState([]);
  const [readingTests, setReadingTests] = useState([]);
  const [writingTests, setWritingTests] = useState([]);
  const [speakingTests, setSpeakingTests] = useState([]);
  const [testCounts, setTestCounts] = useState(null);
  const [testCountsNew, setTestCountsNew] = useState({
    speaking: { attempted: 0, total: 0 },
    writing: { attempted: 0, total: 0 },
    reading: { attempted: 0, total: 0 },
    listening: { attempted: 0, total: 0 },
  });
  
  const globalTotal = Object.values(testCountsNew).reduce((acc, cat) => acc + cat.total, 0);
  const globalAttempted = Object.values(testCountsNew).reduce((acc, cat) => acc + cat.attempted, 0);

  const [speakingScore, setSpeakingScore] = useState(0);
  const [writingsScore, setWritingScore] = useState(0);
  const [readingsScore, setReadingScore] = useState(0);
  const [listeningsScore, setListeningScore] = useState(0);
  const [testTimimgs, setTestTimings] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mockTestType, setMockTestType] = useState("");

  const url = `${Base_URL}/app/admin/mock-tests/get-score?user_id=${userId}&mock_test_attempted_id=${mockTestAttemptedId}`;
  const withCredentials = false;

  useEffect(() => {
    const fetchAndHandleScoreData = async () => {
      const result = await fetchMockTestScore({
        url,
        setIsLoading,
        setMockTestsResult,
        splitCategories,
        setMockTestType,
        withCredentials,
      });

      if (result.success) {
        const counts = getCountsByCategory(result.data);
        setTestCountsNew(counts);
        // toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    };

    fetchAndHandleScoreData();
  }, []);

  const getCountsByCategory = (responses) => {
    const counts = {
        speaking:   { attempted: 0, total: 0 },
        writing:    { attempted: 0, total: 0 },
        reading:    { attempted: 0, total: 0 },
        listening:  { attempted: 0, total: 0 },
    };

    responses.forEach((item) => {
        const cat = item.Category?.toLowerCase();
        if (!cat || !counts[cat]) return;

        counts[cat].total++;

        if (cat === "reading" && item.UserResponse?.autoAttemptedLastOnTimeUp) {
            return;
        }

        if (item.UserResponse?.IsAttempted !== false) {
            counts[cat].attempted++;
        }
    });

    return counts;
  };
  
  const splitCategories = (data) => {
    const categorizedData = data.reduce(
        (acc, curr) => {
          switch (curr.UserResponse.SubCategory) {
            case "Write Essay":
              acc.writing.push(curr);
              acc.we.push(curr);
              break;
            case "Summarize Written Text":
              acc.writing.push(curr);
              acc.swt.push(curr);
              acc.reading.push(curr);
              break;
            case "Reading & Writing: Fill in the Blanks":
              acc.writing.push(curr);
              acc.reading.push(curr);
              acc.rwfib.push(curr);
              break;
            case "Summarize Spoken Text":
              acc.writing.push(curr);
              acc.listening.push(curr);
              acc.sst.push(curr);
              break;
            case "Fill in the Blanks":
              acc.writing.push(curr);
              acc.listening.push(curr);
              acc.lfib.push(curr);
              break;
            case "Write from Dictation":
              acc.writing.push(curr);
              acc.listening.push(curr);
              acc.wfd.push(curr);
              break;
            case "Read Aloud":
              acc.reading.push(curr);
              acc.speaking.push(curr);
              acc.ra.push(curr);
              break;
            case "Multiple Choice, Multiple Answers":
              acc.reading.push(curr);
              acc.mcma.push(curr);
              break;
            case "Re-order Paragraphs":
              acc.reading.push(curr);
              acc.rop.push(curr);
              break;
            case "Multiple Choice, Single Answer":
              acc.reading.push(curr);
              acc.mcsa.push(curr);
              break;
            case "Highlight Correct Summary":
              acc.reading.push(curr);
              acc.listening.push(curr);
              acc.hcs.push(curr);
              break;
            case "Highlight Incorrect Words":
              acc.reading.push(curr);
              acc.listening.push(curr);
              acc.hiw.push(curr);
              break;
            case "Reading: Fill in the Blanks":
              acc.reading.push(curr);
              acc.rfib.push(curr);
              break;
            case "Repeat Sentence":
              acc.speaking.push(curr);
              acc.listening.push(curr);
              acc.rs.push(curr);
              break;
            case "Describe Image":
              acc.speaking.push(curr);
              acc.di.push(curr);
              break;
            case "Re-tell Lecture":
              acc.speaking.push(curr);
              acc.listening.push(curr);
              acc.rl.push(curr);
              break;
            case "Answer Short Question":
              acc.speaking.push(curr);
              acc.listening.push(curr);
              acc.asq.push(curr);
              break;
            default:
              acc.listening.push(curr);
              switch (curr.UserResponse.SubCategory) {
                case "Listening: Multiple Choice, Multiple Answers":
                  acc.lmcma.push(curr);
                  break;
                case "Listening: Multiple Choice, Single Answer":
                  acc.lmcsa.push(curr);
                  break;
                default:
                  acc.smw.push(curr);
                  break;
              }
              break;
          }
          return acc;
        },
        {
          listening: [],
          reading: [],
          writing: [],
          speaking: [],
          ra: [],
          rs: [],
          di: [],
          rl: [],
          asq: [],
          swt: [],
          we: [],
          rwfib: [],
          rfib: [],
          mcma: [],
          mcsa: [],
          rop: [],
          sst: [],
          lmcma: [],
          lmcsa: [],
          hiw: [],
          lfib: [],
          wfd: [],
          hcs: [],
          smw: [],
        }
    );

    setTestCounts([
      categorizedData.ra,
      categorizedData.rs,
      categorizedData.di,
      categorizedData.rl,
      categorizedData.asq,
      categorizedData.swt,
      categorizedData.we,
      categorizedData.rwfib,
      categorizedData.rfib,
      categorizedData.mcma,
      categorizedData.mcsa,
      categorizedData.rop,
      categorizedData.sst,
      categorizedData.lmcma,
      categorizedData.lmcsa,
      categorizedData.hiw,
      categorizedData.lfib,
      categorizedData.wfd,
      categorizedData.hcs,
      categorizedData.smw,
    ]);
    
    setTestTimings({
      "Read Aloud": categorizedData.ra,
      "Repeat Sentence": categorizedData.rs,
      "Describe Image": categorizedData.di,
      "Re-tell Lecture": categorizedData.rl,
      "Answer Short Question": categorizedData.asq,
      "Summarize Written Text": categorizedData.swt,
      "Write Essay": categorizedData.we,
      "Reading & Writing: Fill in the Blanks": categorizedData.rwfib,
      "Reading: Fill in the Blanks": categorizedData.rfib,
      "Multiple Choice, Multiple Answers": categorizedData.mcma,
      "Multiple Choice, Single Answer": categorizedData.mcsa,
      "Re-order Paragraphs": categorizedData.rop,
      "Summarize Spoken Text": categorizedData.sst,
      "Listening: Multiple Choice, Multiple Answers": categorizedData.lmcma,
      "Listening: Multiple Choice, Single Answer": categorizedData.lmcsa,
      "Highlight Incorrect Words": categorizedData.hiw,
      "Listening: Fill in the Blanks": categorizedData.lfib,
      "Write from Dictation": categorizedData.wfd,
      "Highlight Correct Summary": categorizedData.hcs,
      "Select Missing Word": categorizedData.smw,
    });
    setListeningTests(categorizedData.listening);
    setReadingTests(categorizedData.reading);
    setWritingTests(categorizedData.writing);
    setSpeakingTests(categorizedData.speaking);
  };

  const getTimeTaken = (testName = "Read Aloud", mmss = true) => {
    if (testTimimgs && testTimimgs[testName]) {
      const time = testTimimgs[testName].reduce(
        (acc, test) => acc + test.TimeTaken,
        0
      );
      return Number.isInteger(mmss) ? convertSecondsToMMSS(time) : time;
    } else {
      return "00:00";
    }
  };

  const getSpeakingTimeTaken = () => SpeakingQuestionNames.map(getTimeTaken);
  const getTotalSpeakingTimeTaken = () => {
    let tot = 0;
    SpeakingQuestionNames.forEach((test) => {
      tot += getTimeTaken(test, false);
    });
    return convertSecondsToMMSS(tot);
  };

  const getWritingTimeTaken = () => WritingQuestionNames.map(getTimeTaken);
  const getTotalWritingTimeTaken = () => {
    let tot = 0;
    WritingQuestionNames.forEach((test) => {
      tot += getTimeTaken(test, false);
    });
    return convertSecondsToMMSS(tot);
  };

  const getReadingTimeTaken = () => ReadingQuestionNames.map(getTimeTaken);
  const getTotalReadingTimeTaken = () => {
    let tot = 0;
    ReadingQuestionNames.forEach((test) => {
      tot += getTimeTaken(test, false);
    });
    return convertSecondsToMMSS(tot);
  };

  const getListeningTimeTaken = () => ListeningQuestionNames.map(getTimeTaken);
  const getTotalListeningTimeTaken = () => {
    let tot = 0;
    ListeningQuestionNames.forEach((test) => {
      tot += getTimeTaken(test, false);
    });
    return convertSecondsToMMSS(tot);
  };

  const getTotalTimeTaken = () => {
    let tot = 0;

  [
    ListeningQuestionNames,
    ReadingQuestionNames,
    WritingQuestionNames,
    SpeakingQuestionNames,
  ].forEach((questionNames) => {
    questionNames.forEach((test) => {
      tot += getTimeTaken(test, false);
    });
  });

    return convertSecondsToMMSS(tot);
  };

  return mockTestsResult && mockTestsResult[0]?.MockTestName ? (
    <>
      <FlexDiv
        style={{
          flexDirection: "column",
          // padding: isTab ? "1.5rem 2% 2rem" : "6.5rem 3% 2rem",
          padding: isTab ? "0.5rem 1% 2rem" : "2rem 2% 2rem",
        }}
      >
        <AnalyticsHeader>
          <FeedbackHeaderTitle>
            ANALYTICS: <span>{mockTestsResult[0]?.MockTestName}</span>
          </FeedbackHeaderTitle>
        </AnalyticsHeader>
        <WhiteDiv>
          <FlexDiv
            style={{
              padding: "2.5rem 0rem 0rem ",
              width: "96%",
              flexDirection: isTab ? "column" : "row",
              gap: "1.52rem",
            }}
          >
            <FlexDiv
              style={{
                justifyContent: isTab ? "center" : "flex-end",
                width: "50%",
              }}
            >
              <CircleDiv>
                {/* <AnalyticsCircle /> */}
                <PieChart
                  label={({ dataEntry }) => dataEntry.title}
                  labelStyle={{
                    fill: "white",
                    fontSize: "6px",
                    fontFamily: "Helvetica Neue,sans-serif",
                    textShadow: "1px 1px 5px #000",
                  }}
                  labelPosition={65}
                  lineWidth={58}
                  data={[
                    {
                      title: "Reading",
                      value: readingsScore ? readingsScore : 0,
                      color: "#AD826E",
                    },
                    {
                      title: "Listening",
                      value: listeningsScore ? listeningsScore : 0,
                      color: "#868EAF",
                    },
                    {
                      title: "Speaking",
                      value: speakingScore ? speakingScore : 0,
                      color: "#49D7F2",
                    },
                    {
                      title: "Writing",
                      value: writingsScore ? writingsScore : 0,
                      color: "#FF5D5D",
                    },
                  ]}
                />
                <ImgAndLine>
                  <img
                    src={DividerLine}
                    alt=""
                    style={{ width: isMobile ? "" : "100%" }}
                  />
                  <CircleScoresDiv>
                    <PointAndScore>
                      <PointImg src={SpeakingPoint} alt="" />
                      <ScoreDigit color={"#49D7F2"}>
                        {speakingScore ? speakingScore : 0}
                      </ScoreDigit>
                    </PointAndScore>
                    <PointAndScore>
                      <PointImg src={WritingPoint} alt="" />
                      <ScoreDigit color={"#FF5D5D"}>
                        {writingsScore ? writingsScore : 0}
                      </ScoreDigit>
                    </PointAndScore>
                    <PointAndScore>
                      <PointImg src={ReadingPoint} alt="" />
                      <ScoreDigit color={"#AD826E"}>
                        {readingsScore ? readingsScore : 0}
                      </ScoreDigit>
                    </PointAndScore>
                    <PointAndScore>
                      <PointImg src={ListeningPoint} alt="" />
                      <ScoreDigit color={"#868EAF"}>
                        {listeningsScore ? listeningsScore : 0}
                      </ScoreDigit>
                    </PointAndScore>
                  </CircleScoresDiv>
                </ImgAndLine>
              </CircleDiv>
            </FlexDiv>
            <FlexDiv
              style={{ flexDirection: "column", gap: "1.25rem", width: "95%" }}
            >
              <FlexDiv
                style={{
                  flexDirection: isMobile ? "column" : "row",
                  gap: "1.25rem",
                  width: "100%",
                  justifyContent: isTab ? "center" : "flex-start",
                }}
              >
                <AnalyticsTestCard
                  headerName="Speaking"
                  imgSrc={SpeakingImg}
                  color="#49D7F2"
                  score={speakingScore ? speakingScore : 0}
                  questions={`${testCountsNew?.speaking.attempted || 0}/${testCountsNew?.speaking.total || 0}`}
                />
                <AnalyticsTestCard
                  headerName="Writing"
                  imgSrc={WritingImg}
                  color="#FF5D5D"
                  score={writingsScore ? writingsScore : 0}
                  questions={`${testCountsNew?.writing.attempted || 0}/${testCountsNew?.writing.total || 0}`}
                />
              </FlexDiv>

              <FlexDiv
                style={{
                  flexDirection: isMobile ? "column" : "row",
                  gap: "1.25rem",
                  width: "100%",
                  justifyContent: isTab ? "center" : "flex-start",
                }}
              >
                <AnalyticsTestCard
                  headerName="Reading"
                  imgSrc={ReadingImg}
                  color="#AD826E"
                  score={readingsScore ? readingsScore : 0}
                  questions={`${testCountsNew?.reading.attempted || 0}/${testCountsNew?.reading.total || 0}`}
                />
                <AnalyticsTestCard
                  headerName="Listening"
                  imgSrc={ListeningImg}
                  color="#868EAF"
                  score={listeningsScore ? listeningsScore : 0}
                  questions={`${testCountsNew?.listening.attempted || 0}/${testCountsNew?.listening.total || 0}`}
                />
              </FlexDiv>
            </FlexDiv>
          </FlexDiv>
          <FlexDiv style={{ width: isMobile ? "95%" : "100%" }}>
            <AnalyticsForMtScore
              counts={testCounts}
              speakingTotScore={setSpeakingScore}
              writingTotScore={setWritingScore}
              readingTotScore={setReadingScore}
              listeningTotScore={setListeningScore}
            />
          </FlexDiv>
        </WhiteDiv>
      </FlexDiv>
      <FlexDiv
        style={{
          flexDirection: "column",
          padding: isTab ? "0rem 2% 2rem" : "0rem 3% 2rem",
        }}
      >
        <AnalyticsHeader>
          <FeedbackHeaderTitle>
            TIME TAKEN: <span>{getTotalTimeTaken()}</span>{" "}
          </FeedbackHeaderTitle>
        </AnalyticsHeader>
        <WhiteDiv>
          <FlexDiv
            style={{
              flexDirection: "column",
              gap: "1.25rem",
              width: "92%",
              padding: "2.5rem 0rem",
            }}
          >
            <FlexDiv
              style={{
                flexDirection: isMobile ? "column" : "row",
                gap: "1.25rem",
                width: "100%",
                alignItems: "flex-start",
              }}
            >
              <AnalyticsTestCard2
                headerName="Speaking"
                imgSrc={SpeakingImg}
                color="#49D7F2"
                QuestinsTotalTime={getTotalSpeakingTimeTaken()}
                QuestionNames={SpeakingQuestionNames}
                QuestionTimes={getSpeakingTimeTaken()}
              />
              <AnalyticsTestCard2
                headerName="Writing"
                imgSrc={WritingImg}
                color="#FF5D5D"
                QuestinsTotalTime={getTotalWritingTimeTaken()}
                QuestionNames={WritingQuestionNames}
                QuestionTimes={getWritingTimeTaken()}
              />
            </FlexDiv>

            <FlexDiv
              style={{
                flexDirection: isMobile ? "column" : "row",
                gap: "1.25rem",
                width: "100%",
                alignItems: "flex-start",
              }}
            >
              <AnalyticsTestCard2
                headerName="Reading"
                imgSrc={ReadingImg}
                color="#AD826E"
                QuestinsTotalTime={getTotalReadingTimeTaken()}
                QuestionNames={ReadingQuestionNames}
                QuestionTimes={getReadingTimeTaken()}
              />
              <AnalyticsTestCard2
                headerName="Listening"
                imgSrc={ListeningImg}
                color="#868EAF"
                QuestinsTotalTime={getTotalListeningTimeTaken()}
                QuestionNames={ListenQuestionNames}
                QuestionTimes={getListeningTimeTaken()}
              />
            </FlexDiv>
          </FlexDiv>
        </WhiteDiv>
      </FlexDiv>
    </>
  ) : (
    <FlexDiv style={{ height: "100vh" }}>
            {isLoading ? ( <LoadingModal />) : (              
        <div
          style={{
            textAlign: "center",
            fontSize: "20px",
            color: "#999",
            padding: "20px",
            fontWeight: "500",
          }}
        >
          Mock Test Score Not Found in Record.
        </div>)}
    </FlexDiv>
  );
};

export default AnalyticsForTeacher;
