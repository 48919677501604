import React, { useEffect, useState } from "react";
import TestProgress from "../components/Home/TestProgress";
import { FlexDiv } from "../assets/styles/style";
import AverageScore from "../components/Home/AverageScore";
import AiStudyPlan from "../components/Home/AiStudyPlan";
import Navbar from "../components/Navbar/Navbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import Footer from "../components/Home/Footer";
import BrowserNotSupported from "./BrowserNotSupported";
import PortalAnnouncements from "../components/Home/PortalAnnouncements";
import { Base_URL } from "../Client/apiURL";

const isNotChrome = () => {
  const userAgent = navigator.userAgent;
  return !/Chrome/.test(userAgent) || /OPR|Edge/.test(userAgent);
};

const Home = () => {
  const isLaptopTwo = useMediaQuery("(max-width:1000px)");
  const [open, setOpen] = useState(false);
  const [showFooter, setShowFooter] = useState(false);
  const [portalData, setPortalData] = useState({});
  const [averageScores, setAverageScores] = useState([]);

  useEffect(() => {
    setOpen(isNotChrome());

    const userData = JSON.parse(localStorage.getItem("userData") || "{}");
    const portalId = userData?.PortalId || 0;

    setShowFooter(portalId === 1);

    const pd = JSON.parse(localStorage.getItem("portalData") || "{}");
    setPortalData(pd);

    const fetchAverageScores = async () => {
      try {
        const response = await fetch(`${Base_URL}/app/users/average-score`, {
          credentials: 'include'
        });
        const data = await response.json();
        if (data.responseCode === 200) {
          setAverageScores(data.response);
        }
      } catch (error) {
        console.error("Failed to fetch average scores:", error);
      }
    };

    fetchAverageScores();

  }, []);

  return (
    <>
      <PortalAnnouncements portalData={portalData} />

      <BrowserNotSupported open={open} close={setOpen} />
      <Navbar />

      <FlexDiv style={{ padding: isLaptopTwo ? "1.5rem 3% 0rem" : "6.5rem 3% 0rem" }}>
        <AiStudyPlan />
      </FlexDiv>

      <div style={{ padding: "0rem 3% 0rem" }}>
        <AverageScore />
      </div>

      <FlexDiv style={{ padding: "1.5rem 3%" }}>
        <TestProgress averageScores={averageScores} />
      </FlexDiv>

      {showFooter && (
        <FlexDiv style={{ padding: "0rem 3% 0rem", background: "white" }}>
          <Footer />
        </FlexDiv>
      )}
    </>
  );
};

export default Home;