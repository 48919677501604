import React, { useEffect, useState, useRef } from "react";
import TestHeading from "../components/Common/TestHeading";
import DILogo from "../assets/images/S_DI_Logo.svg";
import { S_DI_Subheading } from "../components/Common/Data";
import Navbar from "../components/Navbar/Navbar";
import ButtonList from "../components/Common/ButtonList";
import CommunityScore from "../components/Common/CommunityScore";
import { SWT_MCQCard_Div, SWT_QCard_Div } from "./Style";
import { DescribeImageCardData } from "../components/Speaking/data";
import SpeakingMicCard from "../components/Speaking/SpeakingMicCard";
import DescribeImageCard from "../components/Speaking/DescribeImageCard";
import { FlexDiv } from "../assets/styles/style";
import { useAuth } from "../authentication/Auth";
import SidePannel from "../components/Common/SidePannel";
import Modal from "react-modal";
// import ShowScriptBox from "../components/Common/ShowScriptBox";
import AiScorePopupSpeaking from "../components/Speaking/AiScorePopupSpeaking";
import { AiScorePopupSpeakingData } from "../components/Speaking/data";
Modal.setAppElement("#root");
import { SidePannelBackdrop } from "../components/Common/Style";
import axios from "axios";
import LoadingModal from "../components/Common/LoadingModal";
import AiScorePopupSpeakingDI from "../components/Speaking/AiScorePopupSpeakingDI";
import { Base_URL, Base_URL_AiScore } from "../Client/apiURL";

const questionname = "Describe Image";

const SpeakingDI = () => {
  const [testQuestions, setTestQuestions] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const { logout } = useAuth();
  const [enableSkillsData, setEnableSkillsData] = useState([]);
  const [scoreDataSubmitted, setScoreDataSubmitted] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState("");
  const [selectedAnswers, setSelectedAnswers] = useState([""]);
  const [canSubmit, setCanSubmit] = useState(false);
  const [resetTrigger, setResetTrigger] = useState(false);
  const [questionsData, setQuestionsData] = useState(null);
  const [questionId, setQuestionID] = useState();
  const [testQuestionTableId, setTestQuestionTableId] = useState();
  const [scorecardOpen, setScorecardOpen] = useState(false);
  const [myAttemptedQuestionsScore, setMyAttemptedQuestionsScore] =
    useState(null);
  const [marksObtained, setMarksObtained] = useState(0);
  const [correctAnswers, setCorrectAnswers] = useState([""]);
  const [shouldOpenScorecard, setShouldOpenScorecard] = useState(false);
  const [isScoreDataReadyForSubmit, setIsScoreDataReadyForSubmit] =
    useState(false);
  const [lastScoreUpdate, setLastScoreUpdate] = useState(Date.now());
  const [searchTerm, setSearchTerm] = useState("");
  const [recordedWavFile, setRecordedWavFile] = useState(null);
  const [describeImageScore, setDescribeImageScore] = useState("");
  const [SummaryScore, setSummaryScore] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [totalTestTime, setTotalTestTime] = useState(40000);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [filterBookmarked, setFilterBookmarked] = useState(false);
  const [filterPrediction, setFilterPrediction] = useState(false);
  const [wantToSortDesc, setWantToSortDesc] = useState(false);
  const [highFrequency, setHighFrequency] = useState(false);
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [autoStartRecording, setAutoStartRecording] = useState(false);
  const [isRecordingStarted, setIsRecordingStarted] = useState(false);
  const [isRecordingStopped, setIsRecordingStopped] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [bookmarkId, setBookmarkId] = useState(null);
  const [isPracticed, setIsPracticed] = useState("all");
  const [testAttemptedCount, setTestAttemptedCount] = useState(null);
  const [audioURL, setAudioURL] = useState("");
  const [urlLoading, setUrlLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isAudioPlayerDisabled, setIsAudioPlayerDisabled] = useState(false);
  const audioRef = useRef(null);

  const handleSetLoading = (loadingState) => {
    setIsDataLoading(loadingState);
  };

  const handleImageLoad = () => {
    setIsDataLoading(false);
  };

  // useEffect(() => {
  //   if (questionsData) {
  //     setIsDataLoading(false);
  //   }
  // }, [questionsData]);

  const getSignedURL = async () => {
    const config = {
      method: "get",
      url: `${Base_URL}/app/users/mock-attempted-questions/signed-url`,
    };
    try {
      const response = await axios(config);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      console.error("Error getting signed URL:", error);
      return { error: error.message };
    }
  };

  const uploadAudioFile = async (signedUrl, file) => {
    try {
      const res = await axios.put(signedUrl, file, {
        withCredentials: false,
        headers: {
          "Content-Type": file.type,
        },
      });
      return res.status === 200;
    } catch (error) {
      console.error("Error uploading audio file:", error);
      return false;
    }
  };

  // useEffect(() => {
  //   const handleAudioUpload = async () => {
  //     if (recordedWavFile) {
  //       setUrlLoading(true);
  //       const signedUrlRes = await getSignedURL();
  //       if (signedUrlRes && signedUrlRes.responseCode === 200) {
  //         const uploadSuccess = await uploadAudioFile(signedUrlRes.response, recordedWavFile);
  //         if (uploadSuccess) {
  //           setAudioURL(`https://swift-uni-user-images.s3.us-east-1.amazonaws.com/${signedUrlRes.key}`);
  //           console.log(`https://swift-uni-user-images.s3.us-east-1.amazonaws.com/${signedUrlRes.key}`);
  //         } else { console.error("Failed to upload audio file");
  //         }
  //       } else {
  //         console.error("Failed to get signed URL");
  //       }
  //       setUrlLoading(false);
  //     }
  //   };

  //   handleAudioUpload();
  // }, [recordedWavFile]);

  const updateLocalQuestionBookmark = (
    testQuestionTableId,
    newBookmarkStatus,
    bookmarkId
  ) => {
    setTestQuestions((prevQuestions) => ({
      ...prevQuestions,
      response: prevQuestions.response.map((question) =>
        question.TestQuestionTableId === testQuestionTableId
          ? {
              ...question,
              IsBookMarked: newBookmarkStatus,
              BookMarkedId: newBookmarkStatus ? bookmarkId : null,
            }
          : question
      ),
    }));
  };

  useEffect(() => {
    const question = testQuestions?.response?.find(
      (q) => q.QuestionId === questionId
    );
    if (question) {
      setIsBookmarked(question.IsBookMarked);
      setBookmarkId(question.BookMarkedId);
      setTestAttemptedCount(question.TestAttemptedCount);
    }
  }, [testQuestions, testQuestionTableId]);

  const handleBookmarkChange = (newIsBookmarked, newBookmarkId) => {
    setIsBookmarked(newIsBookmarked);
    setBookmarkId(newBookmarkId);
  };

  const handleRecordingStart = () => {
    setIsRecordingStarted(true);
    // setIsAudioPlayerDisabled(true);
    // if (audioRef.current) {
    //   audioRef.current.pause();
    // }
  };

  const handleRecordingStop = () => {
    setIsRecordingStopped(true);
  };

  useEffect(() => {
    console.log("autoStartRecording in parent: ", autoStartRecording);
  }, [autoStartRecording]);

  useEffect(() => {
    if (
      testQuestions.response &&
      testQuestions.response.length > 0 &&
      currentQuestionIndex < testQuestions.response.length
    ) {
      const question = testQuestions.response[currentQuestionIndex];
      setQuestionID(question.QuestionId);
      setTestQuestionTableId(question.TestQuestionTableId);
    }
  }, [testQuestions, currentQuestionIndex]);

  const handleNextQuestion = () => {
    // console.log("Current Index:", currentQuestionIndex);
    // console.log("Total questions on this page:", testQuestions.response?.length);
    // console.log("Current page:", page);
    // console.log("Total pages:", totalPages);

    if (
      currentQuestionIndex <
      (testQuestions.response ? testQuestions.response.length - 1 : 0)
    ) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else if (page < totalPages) {
      setPage(page + 1);
      setCurrentQuestionIndex(0);
    } else {
      console.log("No more questions to display.");
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    } else if (page > 1) {
      setPage(page - 1);
      setCurrentQuestionIndex(testQuestions.response.length - 1);
    } else {
      console.log("No previous questions to display.");
    }
  };


  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleMyAttemptedQuestionsScore = (score, openScorecard) => {
    setMyAttemptedQuestionsScore(score);
    if (openScorecard) {
      setShouldOpenScorecard(true);
    }
  };

  useEffect(() => {
    if (shouldOpenScorecard) {
      setScorecardOpen(true);
      setShouldOpenScorecard(false);
    }
  }, [shouldOpenScorecard]);

  const handleSetRecordedWavFile = (file) => {
    setRecordedWavFile(file);
  };

  const handleSetCanSubmit = (status) => {
    setCanSubmit(status);
  };

  // function canSubmit() {
  //   false;
  // }

  function getQuestionName() {
    const questionTemp = testQuestions?.response?.find(
      (q) => q.QuestionId === questionId
    );
    if (questionTemp) {
      return questionTemp.QuestionName;
    } else {
      return "loading";
    }
  }

  const handleSearchChange = (newSearchTerm) => {
    setSearchTerm(newSearchTerm);
  };

  useEffect(() => {
    setPage(1);
  }, [filterBookmarked, filterPrediction]);

  const fetchData = async () => {
    const encodedQuestionName = encodeURIComponent(questionname);
    const searchQueryParam = searchTerm ? `&search_name=${encodeURIComponent(searchTerm)}` : "";
    const highFrequencyParam = highFrequency ? `&high_frequency=true` : "";
    let sortDescParam = highFrequency ? "" : `&order_by=${wantToSortDesc ? "asc" : "desc"}`;
    let isPracticedParam = isPracticed === "all" ? "" : `&is_practiced=${isPracticed}`;
    let isPredictionParam = filterPrediction ? `&prediction=${filterPrediction}` : `&prediction=${false}`;
    let isPteCoreParam = false;
   
    const queryParams = `test_name=${encodedQuestionName}&page=${page}&bookmarked=${filterBookmarked}&is_ptecore=${isPteCoreParam}${sortDescParam}${highFrequencyParam}${isPracticedParam}${searchQueryParam}${isPredictionParam}`;

    try {
      const url = `${Base_URL}/app/users/test-questions/get-by-name?${queryParams}`;
      const response = await fetch(url, { credentials: "include" });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data.responseCode === 501) {
        logout();
        navigate("/login");
        return;
      }

      setTestQuestions(data);
      if (data.response && data.response.length > 0) {
        setQuestionID(data.response[0].QuestionId);
        setTestQuestionTableId(data.response[0].TestQuestionTableId);
      }

      const totalQuestions = data.totalQuestions || 1;
      const questionsPerPage = 8;
      const calculatedTotalPages = Math.ceil(
        totalQuestions / questionsPerPage
      );
      setTotalPages(calculatedTotalPages);
    } catch (error) {
      console.error("Failed to fetch test questions:", error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    page,
    searchTerm,
    filterBookmarked,
    filterPrediction,
    isPracticed,
    wantToSortDesc,
    highFrequency,
  ]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const scorecardElement = document.getElementById("scorecard");
      if (scorecardElement && !scorecardElement.contains(event.target)) {
        setScorecardOpen(false);
        setElapsedTime(0);
        setEnableSkillsData([]);
        setMyAttemptedQuestionsScore(null);
        setMarksObtained(0);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleSubmit = async () => {
    setIsLoading(true);

    if (!recordedWavFile) {
      console.error("No audio file to upload");
      setIsLoading(false);
      return;
    }

    try {
      // Upload the audio first
      const signedUrlRes = await getSignedURL();
      if (!signedUrlRes || signedUrlRes.responseCode !== 200) {
        console.error("Failed to get signed URL");
        setIsLoading(false);
        return;
      }

      const uploadSuccess = await uploadAudioFile(
        signedUrlRes.response,
        recordedWavFile
      );
      if (!uploadSuccess) {
        console.error("Failed to upload audio file");
        setIsLoading(false);
        return;
      }

      setAudioURL(
        `https://swift-uni-user-images.s3.us-east-1.amazonaws.com/${signedUrlRes.key}`
      );

      if (questionsData && recordedWavFile) {
        const file = new File([recordedWavFile], "audiofile.wav", {
          lastModified: Date.now,
        });

        let major_aspect = questionsData.response.MajorAspects;
        let minor_aspect = questionsData.response.MinorAspects;
        if (major_aspect.length === 1) {
          major_aspect = [major_aspect[0]];
        }

        if (minor_aspect.length === 1) {
          minor_aspect = [minor_aspect[0]];
        }

        const formData = new FormData();
        formData.append("audiofile", file);
        formData.append("user_text", selectedAnswers);
        formData.append("major_aspects", JSON.stringify(major_aspect));
        formData.append("minor_aspects", JSON.stringify(minor_aspect));

        const response = await fetch(
            `${Base_URL_AiScore}/describeimage`,
          {
            method: "POST",
            body: formData,
          }
        );

        const data = await response.json();

        if (response.ok && data) {
          setDescribeImageScore(data);
          setIsSubmitted(true);
          setScorecardOpen(true);
          setIsScoreDataReadyForSubmit(true);
        } else {
          logout();
          navigate("/login");
        }
      }
    } catch (error) {
      console.error("Submission error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    async function submitScoreData() {
      const userResponse = {
        correctAnswers: questionsData?.response?.QuestionStatement,
        selectedAnswers: selectedAnswers,
        describeImageScore: describeImageScore,
        audioURL: audioURL,
      };

      if (describeImageScore.pronunciation_score === undefined) {
        describeImageScore.pronunciation_score =
          describeImageScore.pronounciation_score;
      }

      const payload = {
        test_question_id: testQuestionTableId,
        marks_obtained:
          describeImageScore.content_score +
          describeImageScore.pronunciation_score +
          describeImageScore.fluency_score,
        user_response: JSON.stringify(userResponse),
        time_taken: elapsedTime,
        is_ptecore: false,
      };

      try {
        const response = await fetch(
          `${Base_URL}/app/users/attempted-questions/add`,
          {
            credentials: "include",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
        const data = await response.json();
        if (data.responseCode === 200) {
          setScoreDataSubmitted(true);
          setIsScoreDataReadyForSubmit(false);
          setLastScoreUpdate(Date.now());
          fetchData();
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error("Failed to submit score data:", error);
      }
    }

    const shouldSubmitScore =
      isScoreDataReadyForSubmit &&
      !scoreDataSubmitted &&
      elapsedTime &&
      audioURL;
    if (shouldSubmitScore) {
      submitScoreData();
    }
  }, [isScoreDataReadyForSubmit, scoreDataSubmitted, elapsedTime, audioURL]);

  const resetState = () => {
    setSelectedAnswers("");
    // setShowScript(false);
    setCanSubmit(false);
    setScorecardOpen(false);
    setElapsedTime(0);
    setIsSubmitted(false);
    setTotalTestTime(totalTestTime);
    setEnableSkillsData([]);
    setScoreDataSubmitted(false);
    setMyAttemptedQuestionsScore(null);
    setMarksObtained(0);
    setShouldOpenScorecard(false);
    setResetTrigger((prevState) => !prevState);
    setRecordedAudio("");
    setIsRecordingStopped(false);
    setIsRecordingStarted(false);
    setAutoStartRecording(false);
    setAudioURL("");
    setIsAudioPlayerDisabled(false);
  };

  useEffect(() => {
    resetState();
  }, [questionId]);

  const handleRedo = () => {
    resetState();
  };

  const modalStyle = {
    overlay: {
      zIndex: 1002,
      backdropFilter: "blur(5px)",
      WebkitBackdropFilter: "blur(5px)",
      background: "none",
    },
    content: {
      border: "none",
      background: "transparent",
      inset: "0px",
      padding: "20px 1%",
    },
  };

  const calculateDaysDifference = (createdAt) => {
    const currentDate = new Date();
    const createdDate = new Date(createdAt);
    const timeDiff = currentDate - createdDate;
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    return daysDiff < 60;
  };

  useEffect(() => {
    setAutoStartRecording(false);
  }, [questionId, resetTrigger]);

  return (
    <>
      {isDataLoading && <LoadingModal />}
      <div id="scorecardd">
        {scorecardOpen && (
          <Modal isOpen={scorecardOpen} style={modalStyle}>
            <AiScorePopupSpeakingDI
              key={AiScorePopupSpeakingData[0].key}
              // EnableSkills={AiScorePopupSpeakingData[0].EnableSkills}
              SmallScoreCard={AiScorePopupSpeakingData[0].SmallScoreCard}
              totalScore={15}
              contentTotalScore={5}
              enableSkillsScore={
                myAttemptedQuestionsScore
                  ? JSON.parse(myAttemptedQuestionsScore.UsersResponse)
                      .describeImageScore
                  : describeImageScore
              }
              UserResponse={
                myAttemptedQuestionsScore
                  ? JSON.parse(myAttemptedQuestionsScore.UsersResponse)
                      .selectedAnswers
                  : selectedAnswers
              }
              recordedAudio={
                myAttemptedQuestionsScore
                  ? JSON.parse(myAttemptedQuestionsScore.UsersResponse).audioURL
                  : audioURL
              }
              OriginalText={questionsData.response.QuestionStatement}
              elapsedTime={
                myAttemptedQuestionsScore
                  ? myAttemptedQuestionsScore.TimeTaken
                  : elapsedTime
              }
              show={true}
              text={selectedAnswers}
              close={setScorecardOpen}
              three={true}
              allData={
                myAttemptedQuestionsScore
                  ? JSON.parse(myAttemptedQuestionsScore.UsersResponse)
                      .describeImageScore
                  : describeImageScore
              }
            />
          </Modal>
        )}
      </div>
      {isSidePanelOpen && <SidePannelBackdrop isOpen={isSidePanelOpen} />}
      <SidePannel
        onToggle={setIsSidePanelOpen}
        testQuestions={testQuestions.response || []}
        totalTestQuestions={testQuestions.totalQuestions}
        heading={"Describe Image"}
        setQuestionsData={setQuestionsData}
        questionID={questionId}
        setQuestionID={setQuestionID}
        setSelectedAnswers={setSelectedAnswers}
        setTriggerReset={setResetTrigger}
        logo={DILogo}
        setTestQuestionTableId={setTestQuestionTableId}
        testQuestionTableId={testQuestionTableId}
        handleSearchChange={handleSearchChange}
        onPageChange={handlePageChange}
        currentPage={page}
        totalPages={totalPages}
        setFilterBookmarked={setFilterBookmarked}
        setFilterPrediction={setFilterPrediction}
        setWantToSortDesc={setWantToSortDesc}
        setHighFrequency={setHighFrequency}
        updateLocalQuestionBookmark={updateLocalQuestionBookmark}
        setIsPracticed={setIsPracticed}
        setDataLoading={handleSetLoading}
        setAutoStartRecording={setAutoStartRecording}
        setCurrentQuestionIndex={setCurrentQuestionIndex}
        fetchData={fetchData}
      />
      <Navbar />
      <FlexDiv style={{ width: "100%" }}>
        <FlexDiv
          style={{ flexDirection: "column", width: "100%", maxWidth: "1880px" }}
        >
          <TestHeading
            logo={DILogo}
            heading={"Describe Image"}
            subheading={S_DI_Subheading}
            serialNo={questionId ? "#" + questionId : "#000"}
            questionName={getQuestionName()}
            // remainTime={false}
            addDictionary={false}
            isSubmitted={isSubmitted}
            // timerMode="decremental"
            totalTestTime={totalTestTime}
            setElapsedTime={setElapsedTime}
            triggerReset={resetTrigger}
            testQuestionTableId={testQuestionTableId}
            appearedCount={questionsData?.response?.AppearedCount}
            IsBookMarked={isBookmarked}
            BookMarkedId={bookmarkId}
            onBookmarkChange={handleBookmarkChange}
            questionID={questionId}
            onCountdownComplete={() => setAutoStartRecording(true)}
            isSpeakingTest={true}
            isRecordingStarted={isRecordingStarted}
            isRecordingStopped={isRecordingStopped}
            preTestCountdownTime="25"
            testAttemptedCount={testAttemptedCount}
            isPrediction={questionsData?.response?.Prediction}
            isNew={calculateDaysDifference(questionsData?.response?.CreatedAt)}
          />

          {questionsData && questionsData.response && (
            <>
              <SWT_QCard_Div>
                <DescribeImageCard
                  id={DescribeImageCardData[0].id}
                  srcImage={questionsData.response.QuestionImage}
                  onImageLoad={handleImageLoad}
                />
              </SWT_QCard_Div>

              <SWT_MCQCard_Div>
                <SpeakingMicCard
                  key={`reset-recording-component-${resetTrigger}`}
                  setSelectedAnswers={setSelectedAnswers}
                  resetTrigger={resetTrigger}
                  setCanSubmit={handleSetCanSubmit}
                  setRecordedAudio={setRecordedAudio}
                  setRecordedWavFile={handleSetRecordedWavFile}
                  autoStartRecording={autoStartRecording}
                  resetAutoStart={() => setAutoStartRecording(false)}
                  handleRecordingStop={handleRecordingStop}
                  handleRecordingStart={handleRecordingStart}
                  totalTestTime={totalTestTime}
                />
              </SWT_MCQCard_Div>
            </>
          )}

          <ButtonList
            onSubmit={() => {
              handleSubmit();
            }}
            onRedo={() => handleRedo()}
            // onScript={() => setShowScript(!showScript)}
            canSubmit={() => !canSubmit}
            isLoading={isLoading}
            urlLoading={urlLoading}
            renderAnswer={false}
            // renderScript={false}
            isSubmitted={isSubmitted}
            onNext={handleNextQuestion}
            onPrevious={handlePreviousQuestion}
            hasPrevious={currentQuestionIndex > 0 || page > 1}
            hasNext={
              currentQuestionIndex <
                (testQuestions.response
                  ? testQuestions.response.length - 1
                  : 0) || page < totalPages
            }
            handleSearchChange={handleSearchChange}
          />
          {/* {showScript && questionsData?.response?.QuestionStatement && (
            <ShowScriptBox
              answerText={questionsData?.response?.QuestionStatement}
            />
          )} */}
          <CommunityScore
            testQuestionTableId={testQuestionTableId}
            onSelectMyScore={handleMyAttemptedQuestionsScore}
            lastScoreUpdate={lastScoreUpdate}
            ScoreLetter="S"
            bg="#49D7F2"
            totalScore="15.00"
            testSubmissionInProcess={isLoading}
          />
        </FlexDiv>
      </FlexDiv>
    </>
  );
};

export default SpeakingDI;
